import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { setActiveSort } from '../../helpers/product';

const ShopSize = ({ disc, getSortParams }) => {
  const [selectedSize, setSelectedSize] = useState('');

  const handleSizeClick = (disc) => {
    setSelectedSize(disc);
    getSortParams('disPer', disc);
    setActiveSort({ target: { value: disc } }); // Assuming setActiveSort expects an event-like object
  };

  return (
    <div className="sidebar-widget mt-40">
      <h4 className="pro-sidebar-title">Discounts</h4>
      <div className="sidebar-widget-list mt-20">
        {disc && disc.length > 0 ? (
          <ul>
            <li>
              <div className="sidebar-widget-list-left">
                <button
                  className={selectedSize === '' ? 'active' : ''}
                  onClick={() => handleSizeClick('')}
                >
                  <span className="checkmark" /> All Discounts
                </button>
              </div>
            </li>
            {disc.map((disPer, key) => (
              <li key={key}>
                <div className="sidebar-widget-list-left">
                  <button
                    className={selectedSize === disPer ? 'active' : ''}
                    onClick={() => handleSizeClick(disPer)}
                  >
                    <span className="checkmark" />
                    {disPer}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          'No Discounts found'
        )}
      </div>
    </div>
  );
};

ShopSize.propTypes = {
  disc: PropTypes.array.isRequired,
  getSortParams: PropTypes.func.isRequired
};

export default ShopSize;






// import PropTypes from "prop-types";

// import { setActiveSort } from "../../helpers/product";

// const ShopSize = ({ sizes, getSortParams }) => {
//   return (
//     <div className="sidebar-widget mt-40">
//       <h4 className="pro-sidebar-title">Category </h4>
//       <div className="sidebar-widget-list mt-20">
//         {sizes ? (
//           <ul>
//             <li>
//               <div className="sidebar-widget-list-left">
//                 <button
//                   onClick={e => {
//                     getSortParams("size", "");
//                     setActiveSort(e);
//                   }}
//                 >
//                   <span className="checkmark" /> All Category{" "}
//                 </button>
//               </div>
//             </li>
//             {sizes.map((size, key) => {
//               return (
//                 <li key={key}>
//                   <div className="sidebar-widget-list-left">
//                     <button
//                       className="text-uppercase"
//                       onClick={e => {
//                         getSortParams("size", size);
//                         setActiveSort(e);
//                       }}
//                     >
//                       {" "}
//                       <span className="checkmark" />
//                       {size}{" "}
//                     </button>
//                   </div>
//                 </li>
//               );
//             })}
//           </ul>
//         ) : (
//           "No Category found"
//         )}
//       </div>
//     </div>
//   );
// };

// ShopSize.propTypes = {
//   getSortParams: PropTypes.func,
//   sizes: PropTypes.array
// };

// export default ShopSize;
