import { Fragment, useState, useEffect } from 'react';
import Paginator from 'react-hooks-paginator';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getSortedProducts } from '../../helpers/product';
import SEO from "../../components/seo";
import LayoutOne from '../../layouts/LayoutOne';
import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
import MyShopSidebar from '../../wrappers/product/MyShopSidebar';
import ShopTopbar from '../../wrappers/product/ShopTopbar';
import ShopProducts from '../../wrappers/product/ShopProducts';
import setFilteredProducts from "../../store/slices/product-slice";
import { getNewFilterdProducts } from '../../store/actions/product-actions';

const MyShopGridStandard = () => {
    const [layout, setLayout] = useState('grid three-column');
    const [sortType, setSortType] = useState('');
    const [sortValue, setSortValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [currentData, setCurrentData] = useState([]);

    const { filteredProducts } = useSelector(state => state.product);
    const dispatch = useDispatch();
    

    useEffect(() => {
      dispatch(getNewFilterdProducts({ page: currentPage, limit: pageLimit }));
  }, [dispatch, currentPage, pageLimit]);

  // Update current data based on sorted products and pagination
  useEffect(() => {
      const sortedProducts = getSortedProducts(filteredProducts, sortType, sortValue);
      setCurrentData(sortedProducts.slice((currentPage - 1) * pageLimit, currentPage * pageLimit));
  }, [currentPage, filteredProducts, sortType, sortValue, pageLimit]);
    
    // useEffect(() => {
    //     dispatch(getNewFilterdProducts({ page, limit }));
    // }, [dispatch, page, limit]);

    // useEffect(() => {
    //     console.log("Filtered Products:", filteredProducts);
    //     const sortedProducts = getSortedProducts(filteredProducts, sortType, sortValue);
    //     setCurrentData(sortedProducts.slice((currentPage - 1) * pageLimit, currentPage * pageLimit));
    // }, [currentPage, filteredProducts, sortType, sortValue, pageLimit]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
      <Fragment>
        <SEO titleTemplate="Shop Page" description="Shop page of eCommerce template." />
        <LayoutOne headerTop="visible">
          <Breadcrumb
            pages={[
              { label: "Home", path: process.env.PUBLIC_URL + "/" },
              { label: "Shop", path: process.env.PUBLIC_URL + "/" }
            ]}
          />
          <div className="shop-area pt-95 pb-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 order-2 order-lg-1">
                  <MyShopSidebar sideSpaceClass="mr-30" />
                </div>
                <div className="col-lg-9 order-1 order-lg-2">
                  <ShopTopbar getLayout={setLayout} productCount={filteredProducts.length} sortedProductCount={currentData.length} getFilterSortParams={(sortType, sortValue) => { 
                    setSortType(sortType);
                    setSortValue(sortValue);
                  }} />
                  <ShopProducts layout={layout} products={currentData} />
                  <Paginator
                    totalRecords={filteredProducts.length}
                    pageLimit={pageLimit}
                    pageNeighbours={2}
                    currentPage={currentPage}
                    setCurrentPage={handlePageChange}
                    setOffset={() => {}}
                    pageContainerClass="mb-0 mt-0"
                    pagePrevText="«"
                    pageNextText="»"
                  />
                  {/* <Paginator
                    totalRecords={filteredProducts.length}
                    pageLimit={pageLimit}
                    pageNeighbours={2}
                    setOffset={() => {}}
                    currentPage={currentPage}
                    setCurrentPage={handlePageChange}
                    pageContainerClass="mb-0 mt-0"
                    pagePrevText="«"
                    pageNextText="»"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </LayoutOne>
      </Fragment>
    );
};

export default MyShopGridStandard;



// import { Fragment, useState, useEffect } from 'react';
// import Paginator from 'react-hooks-paginator';
// import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom"
// import { getSortedProducts } from '../../helpers/product';
// import SEO from "../../components/seo";
// import LayoutOne from '../../layouts/LayoutOne';
// import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
// import MyShopSidebar from '../../wrappers/product/MyShopSidebar';
// import ShopTopbar from '../../wrappers/product/ShopTopbar';
// import ShopProducts from '../../wrappers/product/ShopProducts';
// import setFilteredProducts from "../../store/slices/product-slice";
// import { getNewFilterdProducts } from '../../store/actions/product-actions';

// const MyShopGridStandard = () => {
//     const [layout, setLayout] = useState('grid three-column');
//     const [sortType, setSortType] = useState('');
//     const [sortValue, setSortValue] = useState('');
//     const [offset, setOffset] = useState(0);
//     const [currentData, setCurrentData] = useState([]);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [pageLimit, setPageLimit] = useState(15);
    
//     const { filteredProducts } = useSelector(state => state.product);

//     const dispatch = useDispatch();
  
//     const getLayout = (layout) => {
//       setLayout(layout);
//     };

//     const getFilterSortParams = (sortType, sortValue) => {
//         // Dispatch action to update sort filter
//         console.log("Sorting with:", sortType, sortValue);
//         dispatch(setFilteredProducts({ type: sortType, value: sortValue }));
//       };

//       const page = 1;
//       const limit = 10;
//       const brandId = ''; // Example brand ID
//       const category = '';
//       const disPer = ''; // Example discount percentage
//       const color = '';

//       useEffect(() => {
//         dispatch(getNewFilterdProducts({ page, limit, brandId, category, disPer, color }));
//       }, [dispatch, page, limit, brandId, category, disPer, color]);
  
//     // const getSortParams = (sortType, sortValue) => {
//     //   setSortType(sortType);
//     //   setSortValue(sortValue);
//     // };

//     useEffect(() => {
//       console.log("Filtered Products:", filteredProducts);
//       const sortedProducts = getSortedProducts(filteredProducts, sortType, sortValue);
//       console.log("Sorted Products:", sortedProducts);
//       setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
//     }, [offset, filteredProducts, sortType, sortValue]);
    
  
//     // useEffect(() => {
//     //   const sortedProducts = getSortedProducts(filteredProducts, sortType, sortValue);
//     //   setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
//     // }, [offset, filteredProducts, sortType, sortValue]);
  
//     return (
//       <Fragment>
//         <SEO titleTemplate="Shop Page" description="Shop page of eCommerce template." />
//         <LayoutOne headerTop="visible">
//           <Breadcrumb
//             pages={[
//               { label: "Home", path: process.env.PUBLIC_URL + "/" },
//               { label: "Shop", path: process.env.PUBLIC_URL + "/" }
//             ]}
//           />
//           <div className="shop-area pt-95 pb-100">
//             <div className="container">
//               <div className="row">
//                 <div className="col-lg-3 order-2 order-lg-1">
//                   <MyShopSidebar sideSpaceClass="mr-30" />
//                 </div>
//                 <div className="col-lg-9 order-1 order-lg-2">
//                   <ShopTopbar getLayout={getLayout} productCount={filteredProducts.length} sortedProductCount={currentData.length} getFilterSortParams={getFilterSortParams}  />
//                   <ShopProducts layout={layout} products={currentData} />
//                   <Paginator
//                     totalRecords={filteredProducts.length}
//                     pageLimit={pageLimit}
//                     pageNeighbours={2}
//                     setOffset={setOffset}
//                     currentPage={currentPage}
//                     setCurrentPage={setCurrentPage}
//                     pageContainerClass="mb-0 mt-0"
//                     pagePrevText="«"
//                     pageNextText="»"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </LayoutOne>
//       </Fragment>
//     );
//   };
  
//   export default MyShopGridStandard;
  

// const MyShopGridStandard = () => {
//     const [layout, setLayout] = useState('grid three-column');
//     const [sortType, setSortType] = useState('');
//     const [sortValue, setSortValue] = useState('');
//     const [filterSortType, setFilterSortType] = useState('');
//     const [filterSortValue, setFilterSortValue] = useState('');
//     const [offset, setOffset] = useState(0);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [currentData, setCurrentData] = useState([]);
//     const [sortedProducts, setSortedProducts] = useState([]);
//     const  products  = useSelector((state) => state.product.prod);

//     console.log("shop grid products :", products);
    

//     const pageLimit = 15;
//     let { pathname } = useLocation();

//     const getLayout = (layout) => {
//         setLayout(layout)
//     }

//     const getSortParams = (sortType, sortValue) => {
//         setSortType(sortType);
//         setSortValue(sortValue);
//     }

//     const getFilterSortParams = (sortType, sortValue) => {
//         setFilterSortType(sortType);
//         setFilterSortValue(sortValue);
//     }

//     useEffect(() => {
//         let sortedProducts = getSortedProducts(products, sortType, sortValue);
//         const filterSortedProducts = getSortedProducts(sortedProducts, filterSortType, filterSortValue);
//         sortedProducts = filterSortedProducts;
//         setSortedProducts(sortedProducts);
//         setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
//     }, [offset, products, sortType, sortValue, filterSortType, filterSortValue ]);

//     console.log("currentData :", currentData);
//     console.log("getFilterSortParams :", getFilterSortParams);
//     console.log("sortedProducts :", sortedProducts);
    

//     return (
//         <Fragment>
//             <SEO
//                 titleTemplate="Shop Page"
//                 description="Shop page of 09 Mobile Gallery react minimalist eCommerce template."
//             />

//             <LayoutOne headerTop="visible">
//                 {/* breadcrumb */}
//                 <Breadcrumb 
//                     pages={[
//                         {label: "Home", path: process.env.PUBLIC_URL + "/" },
//                         {label: "Shop", path: process.env.PUBLIC_URL + pathname }
//                     ]} 
//                 />

//                 <div className="shop-area pt-95 pb-100">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-lg-3 order-2 order-lg-1">
//                                 {/* shop sidebar */}
//                                 <MyShopSidebar products={products} getSortParams={getSortParams} sideSpaceClass="mr-30"/>
//                             </div>
//                             <div className="col-lg-9 order-1 order-lg-2">
//                                 {/* shop topbar default */}
//                                 <ShopTopbar getLayout={getLayout} getFilterSortParams={getFilterSortParams} productCount={products.length} sortedProductCount={currentData.length} />

//                                 {/* shop page content default */}
//                                 <ShopProducts layout={layout} products={currentData} />

//                                 {/* shop product pagination */}
//                                 {/* <div className="pro-pagination-style text-center mt-30">
//                                     <Paginator
//                                         totalRecords={sortedProducts.length}
//                                         pageLimit={pageLimit}
//                                         pageNeighbours={2}
//                                         setOffset={setOffset}
//                                         currentPage={currentPage}
//                                         setCurrentPage={setCurrentPage}
//                                         pageContainerClass="mb-0 mt-0"
//                                         pagePrevText="«"
//                                         pageNextText="»"
//                                     />
//                                 </div> */}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </LayoutOne>
//         </Fragment>
//     )
// }


// export default MyShopGridStandard;