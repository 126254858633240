import axiosInstance from '../../axios';
import { setCartItems } from '../slices/cart-slice';

export const getCartProducts = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("cart/get");
    if (res.data.code === 1) {
      // dispatch(setProd(res.data.data));
      dispatch(setCartItems(res.data.data));
      
    } else {
      // Handle case where the code is not 1 if needed
      console.error('Unexpected response code:', res.data.code);
    }
  } catch (err) {
    console.error('Failed to fetch banners:', err);
  }
};