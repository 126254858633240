const { createSlice } = require('@reduxjs/toolkit');

const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
        banners: [],
        sliderBanners: [],
        testimonialBanners: [],
    },
    reducers: {
        setBanners(state, action) {
            state.banners = action.payload;
        },
        setSliderBanners(state, action) {
            state.sliderBanners = action.payload;
        },
        setTestimonialBanners(state, action) {
            state.testimonialBanners = action.payload;
        }
    },
});

export const { setBanners, setSliderBanners, setTestimonialBanners } = bannerSlice.actions;
export default bannerSlice.reducer;